import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import heroGraphic from '../assets/HeroGraphic.svg';

const BlogPage = ({ pageContext, ...props }) => (
	<>
		<SEO
			title={pageContext.title}
			description={pageContext.description}
			image={pageContext.feature}
			{...props}
		>
			<Header />
			<div className="flex mx-auto max-w-5xl w-full  pb-0 xl:pb-20 pt-8  lg:pt-12 lg:pb-16 2xl:py-20 text-left">
				<div className="px-4 mb-10 relative">

					<h2 className="z-20 relative">
						{pageContext.title}

					</h2>
					<img
						src={heroGraphic}
						alt="Swirl Line"
						className="-left-20 -top-12 absolute overflow-hidden z-0"
					/>
					<p className="mb-5">
						{pageContext.date}
					</p>
					<div
						id="blog-content"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: pageContext.content }}
					/>

					{!pageContext.hideAbout
					&& (
						<>
							<h3 className="my-5  font-bold text-buddyred">About Buddy</h3>
							<p className="mb-10"> Buddy’s industry transforming Insurance Gateway is the simplest way for software companies to manage and sell insurance products to customers. Built for businesses but designed for developers, Buddy's single, forward-compatible connection enables software companies to offer and manage insurance without costly engineering hours. Buddy is based in Richmond, Va., and is an alumnus of Techstars and Plug and Play Accelerators. Buddy was named a 2019 “Outdoor Innovator” by Outside Magazine and an “Insurance Innovator to Watch” by Digital Insurance. Buddy is funded by Atypical Ventures, Sequoia Scout Fund, CIT, Techstars, and Plug and Play, as well as angel investors. </p>

						</>
					)}
					<hr className="my-3" />

					<div className="flex flex-col lg:flex-row lg: justify-between lg:items-center">
						<div>
							<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl ">Contact Info</h2>
							<p className="text-gray-500">
								P.O. Box 81
								<br />
								{' '}
								Richmond, VA 23218-0081
								<br />
								<div className="text-base text-gray-500 hover:text-gray-900 mt-4">
									<a
										className=""
										href="tel:+18334628339"
									>
										833.462.8339
									</a>
								</div>
								<div className="text-base text-gray-500 hover:text-gray-900 lg:mt-4">
									<a
										className=""
										href="mailto:buddy@buddy.insure"
									>
										buddy@buddy.insure
									</a>
								</div>
							</p>
						</div>

						<Link
							to="/contact"
							className="buddy-btn-mobelux w-full lg:w-1/6 h-5 mt-5"
						>
							Contact Us
						</Link>

					</div>

				</div>
			</div>

			<Footer />
		</SEO>
	</>
);

export default BlogPage;
